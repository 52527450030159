import { Grid, GridItem } from '@rsa-digital/evo-shared-components/components/Grid';
import RichText from '@rsa-digital/evo-shared-components/components/RichText';
import fonts from '@rsa-digital/evo-shared-components/helpers/fonts';
import mediaQuery from '@rsa-digital/evo-shared-components/helpers/mediaQuery';
import spacing from '@rsa-digital/evo-shared-components/helpers/spacing';
import styled from 'styled-components';

export const ModalHeading = styled.h2`
  ${fonts.headingSmall};
  margin: 0 0 ${spacing(4)};
`;

export const BodyRichText = styled(RichText)`
  margin-bottom: ${spacing(4)};
`;

export const DifferentNeedsRichText = styled(RichText)`
  margin: ${spacing(4)} 0 ${spacing(2)};
`;

export const RadioInputGrid = styled(Grid)`
  & {
    margin-bottom: ${spacing(2)};
  }
`;

export const ModalGridItem = styled(GridItem)`
  padding: 0 ${spacing(3)};

  ${mediaQuery.tabletPortrait`
    padding: 0 ${spacing(7)};
  `}

  ${mediaQuery.tabletLandscape`
    padding: 0;
  `}
`;
