import { FlexibleWidthDivider } from '@rsa-digital/evo-shared-components/components/Divider';
import { GridItem } from '@rsa-digital/evo-shared-components/components/Grid';
import Image from '@rsa-digital/evo-shared-components/components/Image';
import componentProps, {
  ComponentProps,
} from '@rsa-digital/evo-shared-components/helpers/componentProps';
import { graphql, useStaticQuery } from 'gatsby';
import React, { MouseEventHandler } from 'react';
import { PetType, ProductFeature } from 'components/QuoteSummary/ProductFeaturesTable';
import { processImageAsset } from 'helpers/csTypeProcessors';
import { PageTitle } from 'helpers/eventTracking';
import {
  classicPremierCoverPercentagePlaceholderData,
  classicPremierCoverPercentagePlaceholders,
} from 'helpers/placeholders/classicPremierCoverPercentagePlaceholders';
import { coverPercentagePlaceholders } from 'helpers/placeholders/coverPercentagePlaceholders';
import { quotePlaceholders } from 'helpers/placeholders/quotePlaceholders';
import {
  replacePlaceholdersPlainText,
  replacePlaceholdersRichText,
} from 'helpers/placeholders/replaceCsPlaceholders';
import { formatInPoundsWithSign } from 'helpers/poundsHelpers';
import { Product } from 'helpers/productHelpers';
import { petType_CAT, petType_DOG } from 'helpers/referenceDataConstants';
import { useCurrentQuote } from 'helpers/useCurrentQuote';
import usePercentageOfClaimsCovered from 'helpers/usePercentageOfClaimsCovered';
import { ExtraCoverFeeLimit, PremierCoverFeeLimit } from 'state/formData/quoteOptions';
import { CsAsset } from 'types/contentStack';
import ProductFeatureSection from './ProductFeatureSection';
import ProductSummarySection from './ProductSummarySection';
import VetFeeLimitSection from './VetFeeLimitSection';
import {
  GridWithFullWidth,
  GridWithMarginTop,
  ImageWrapper,
  MoneyFactsBanner,
  ParagraphLightWithMarginTop,
  ProductDetailsContainer,
  SectionHeading,
  StyledFlexibleWidthDivider,
  StyledGrid,
  StyledRichText,
  TextWrapper,
  TitleBannerConainer,
  TitleText,
} from './styles';

export type CsSummaryOfCover = {
  summary_id: string;
  summary_table_heading: string;
  vet_fee_limits: {
    limit_text: string;
    limit_text_multipet: string;
    default_fee_limit?: string;
    for_each_text: string;
    or_text?: string;
    cover_duration_text: string;
    treatment_cost_link: string;
  };
  feature_list_heading: string;
};

type SummaryOfCoverCsProps = {
  allCsPetSummaryOfCover: {
    nodes: CsSummaryOfCover[];
  };
  csPetSummaryOfCoverInstallmentInfo: {
    annual_installment_text: string;
    monthly_installment_text: string;
    switch_to_annual_link_text: string;
    switch_to_monthly_link_text: string;
    price_panel_copy: string;
  };
  csPetSummaryOfCoverChecklist: {
    product_feature: ProductFeature[];
  };
  csPetQuoteSummaryPercentageOfClaimsCoveredBanner: {
    donut_chart_rich_text: string;
    donut_chart_rich_text_for_standard: string;
    standard_cover_level_subheading: string;
    extra_cover_level_subheading: string;
    premier_cover_level_subheading: string;
    claims_caveat_text: {
      dog_text: string;
      cat_text: string;
    };
  };
  csPetMoneyfactsBanner: {
    moneyfacts_banner_content: {
      dog_image: CsAsset | null;
      cat_image: CsAsset | null;
      heading: string;
      content_rich_text: string;
      active_cover_levels: string;
    };
  };
};

type SummaryOfCoverProps = {
  isAnnualPayment: boolean;
  paymentOnClick: MouseEventHandler;
  treatmentCostOnClick: MouseEventHandler;
  product: Product;
  insuredPetTypes: PetType;
  productSummaryRef: React.RefObject<HTMLDivElement>;
  pageTitle: PageTitle;
  vetFeeLimit: ExtraCoverFeeLimit | PremierCoverFeeLimit | undefined;
} & ComponentProps;

const query = graphql`
  query {
    allCsPetSummaryOfCover {
      nodes {
        summary_id
        summary_table_heading
        vet_fee_limits {
          limit_text
          limit_text_multipet
          default_fee_limit
          for_each_text
          or_text
          cover_duration_text
          treatment_cost_link
        }
        feature_list_heading
      }
    }
    csPetSummaryOfCoverInstallmentInfo {
      annual_installment_text
      monthly_installment_text
      switch_to_annual_link_text
      switch_to_monthly_link_text
      price_panel_copy
    }
    csPetSummaryOfCoverChecklist {
      product_feature {
        display_on_products_selector {
          product_selector
        }
        included_with_products_selector {
          product_selector
        }
        applicable_pets
        modal {
          modal_id
        }
        feature_text
      }
    }
    csPetQuoteSummaryPercentageOfClaimsCoveredBanner {
      donut_chart_rich_text
      donut_chart_rich_text_for_standard
      standard_cover_level_subheading
      extra_cover_level_subheading
      premier_cover_level_subheading
      claims_caveat_text {
        dog_text
        cat_text
      }
    }
    csPetMoneyfactsBanner {
      moneyfacts_banner_content {
        dog_image {
          ...CsAsset
        }
        cat_image {
          ...CsAsset
        }
        heading
        content_rich_text
        active_cover_levels
      }
    }
  }
`;

const SummaryOfCover: React.FC<SummaryOfCoverProps> = ({
  isAnnualPayment,
  paymentOnClick,
  treatmentCostOnClick,
  product,
  insuredPetTypes,
  productSummaryRef,
  pageTitle,
  vetFeeLimit,
  ...props
}) => {
  const {
    csPetSummaryOfCoverInstallmentInfo: {
      annual_installment_text,
      monthly_installment_text,
      switch_to_annual_link_text,
      switch_to_monthly_link_text,
      price_panel_copy,
    },
    csPetSummaryOfCoverChecklist: { product_feature },
    csPetQuoteSummaryPercentageOfClaimsCoveredBanner: {
      donut_chart_rich_text,
      donut_chart_rich_text_for_standard,
      standard_cover_level_subheading,
      premier_cover_level_subheading,
      extra_cover_level_subheading,
      claims_caveat_text,
    },
    csPetMoneyfactsBanner: { moneyfacts_banner_content },
    allCsPetSummaryOfCover,
  } = useStaticQuery<SummaryOfCoverCsProps>(query);

  const getCoverSummaryData = (currentProduct: Product): CsSummaryOfCover | undefined => {
    return allCsPetSummaryOfCover.nodes.find((n) => n.summary_id === currentProduct);
  };
  const coverSummaryData = getCoverSummaryData(product);
  const getVetFeeLimit = (): string => {
    if (coverSummaryData && coverSummaryData.vet_fee_limits.default_fee_limit)
      return coverSummaryData.vet_fee_limits.default_fee_limit;
    return '';
  };
  const MoneyFactsBannerData = moneyfacts_banner_content;
  const MoneyFactsDogLogo = processImageAsset(MoneyFactsBannerData.dog_image);
  const MoneyFactsCatLogo = processImageAsset(MoneyFactsBannerData.cat_image);
  const quote = useCurrentQuote();
  const numberOfPetsInQuote = quote.petInfos?.length || 0;
  const pets = quote.petInfos;
  const quoteHasDog = pets?.some((pet) => pet.petType === petType_DOG);
  const quoteHasCat = pets?.some((pet) => pet.petType === petType_CAT);
  const getVetFeeLimitText = (petsInQuote: number): string | undefined =>
    petsInQuote > 1
      ? coverSummaryData?.vet_fee_limits.limit_text_multipet
      : coverSummaryData?.vet_fee_limits.limit_text;

  const percentageOfClaimsCoveredDisclaimer =
    product === Product.Extra
      ? extra_cover_level_subheading
      : premier_cover_level_subheading;

  const standardPercentageOfClaimsCoveredDisclaimer = standard_cover_level_subheading;

  const getPetText = (): string => {
    switch (insuredPetTypes) {
      case PetType.CatsOnly:
        return claims_caveat_text.cat_text;
      case PetType.DogsOnly:
        return claims_caveat_text.dog_text;
      default:
        return '';
    }
  };

  const substituteQuotePlacholders = replacePlaceholdersRichText(
    quotePlaceholders,
    quote
  );

  const coverPercentage = usePercentageOfClaimsCovered(
    product,
    vetFeeLimit || 0,
    quote.petInfos ?? []
  );

  const coverPlaceholderData: classicPremierCoverPercentagePlaceholderData = {
    coveredClaimsPercentage: coverPercentage,
    coverLimit: vetFeeLimit ? formatInPoundsWithSign(vetFeeLimit) : '',
    petType: getPetText(),
  };

  const substituteCoverPercentagePlaceholders = replacePlaceholdersPlainText(
    classicPremierCoverPercentagePlaceholders,
    coverPlaceholderData
  );

  const substituteCoverPercentagePlaceholdersRichText = replacePlaceholdersRichText(
    coverPercentagePlaceholders,
    coverPlaceholderData
  );

  const extraOrPremierQuote = product === Product.Extra || product === Product.Premier;
  const standardQuote = product === Product.Standard;
  const standardOrExtraOrPremierQuote =
    product === Product.Standard ||
    product === Product.Extra ||
    product === Product.Premier;

  return (
    <GridWithMarginTop alignLeft>
      {coverSummaryData && (
        <GridItem
          desktop={10}
          tabletLandscape={10}
          tabletPortrait={8}
          {...componentProps(props)}>
          <TitleBannerConainer>
            <TitleText>{coverSummaryData.summary_table_heading}</TitleText>
          </TitleBannerConainer>
          <ProductDetailsContainer>
            <ProductSummarySection
              isAnnualPayment={isAnnualPayment}
              paymentTypeText={
                isAnnualPayment ? annual_installment_text : monthly_installment_text
              }
              pricePanelCopy={price_panel_copy}
              paymentOnClick={paymentOnClick}
              switchInstallmentTypeLink={
                isAnnualPayment ? switch_to_monthly_link_text : switch_to_annual_link_text
              }
              product={product}
              donutChartText={substituteCoverPercentagePlaceholdersRichText(
                standardQuote ? donut_chart_rich_text_for_standard : donut_chart_rich_text
              )}
              vetFeeLimit={vetFeeLimit}
              extraOrPremierQuote={extraOrPremierQuote}
              standardOrExtraOrPremierQuote={standardOrExtraOrPremierQuote}
              productSummaryRef={productSummaryRef}
            />
            {standardQuote && (
              <>
                <ParagraphLightWithMarginTop>
                  {substituteCoverPercentagePlaceholdersRichText(
                    standardPercentageOfClaimsCoveredDisclaimer
                  )}
                </ParagraphLightWithMarginTop>
                <StyledFlexibleWidthDivider>
                  <FlexibleWidthDivider aria-hidden />
                </StyledFlexibleWidthDivider>
              </>
            )}
            <VetFeeLimitSection
              vetFeeLimitText={getVetFeeLimitText(numberOfPetsInQuote)}
              vetFeeLimit={getVetFeeLimit()}
              forEachText={coverSummaryData.vet_fee_limits.for_each_text}
              optionalOrText={coverSummaryData.vet_fee_limits.or_text}
              coverDurationText={substituteQuotePlacholders(
                coverSummaryData.vet_fee_limits.cover_duration_text
              )}
              treatmentCostLink={coverSummaryData.vet_fee_limits.treatment_cost_link}
              treatmentCostOnClick={treatmentCostOnClick}
              pageTitle={pageTitle}
              extraOrPremierQuote={extraOrPremierQuote}
              percentageOfClaimsCoveredDisclaimer={substituteCoverPercentagePlaceholders(
                percentageOfClaimsCoveredDisclaimer
              )}
            />
            {standardOrExtraOrPremierQuote && (
              <GridWithFullWidth>
                <FlexibleWidthDivider aria-hidden />
                <MoneyFactsBanner>
                  <StyledGrid>
                    <ImageWrapper>
                      {MoneyFactsDogLogo && quoteHasDog && (
                        <Image image={MoneyFactsDogLogo} />
                      )}
                      {MoneyFactsCatLogo && quoteHasCat && !quoteHasDog && (
                        <Image image={MoneyFactsCatLogo} />
                      )}
                    </ImageWrapper>
                    <TextWrapper>
                      <SectionHeading>{moneyfacts_banner_content.heading}</SectionHeading>
                      <StyledRichText
                        html={moneyfacts_banner_content.content_rich_text}
                        pageTitle={pageTitle}
                      />
                    </TextWrapper>
                  </StyledGrid>
                </MoneyFactsBanner>
                <FlexibleWidthDivider aria-hidden />
              </GridWithFullWidth>
            )}
            <ProductFeatureSection
              sectionHeading={coverSummaryData.feature_list_heading}
              features={product_feature}
              product={product}
              insuredPetTypes={insuredPetTypes}
              pageTitle={pageTitle}
            />
          </ProductDetailsContainer>
        </GridItem>
      )}
    </GridWithMarginTop>
  );
};

export default SummaryOfCover;
